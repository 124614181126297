<template>
  <v-card
    class="d-flex align-center justify-center rounded-0 bg"
    width="100vw"
    min-height="100vh"
    height="wrap-content"
  >
    <div class="logo">
      <img src="../../../assets/epicor.png" alt="Vue" />
    </div>
    <div>
      <v-card
        class="pa-10 d-flex flex-column align-center justify-space-between rounded-xl"
        style="background-color: rgba(255, 255, 255, 1)"
        width="400px"
        height="auto"
      >
        <h3>{{ isLogin ? $t("logIn") : $t("register") }}</h3>
        <div style="width: 100%">
          <v-text-field
            name="input-10-2"
            :label="this.$t('name')"
            value=""
            color="#333333"
            :rules="[rules.required, rules.min]"
            v-model="userInput.userName"
            class="input-group--focused"
            v-show="!isLogin"
          />

          <v-text-field
            name="input-10-2"
            label="Company"
            v-show="!isLogin"
            :rules="[rules.required, rules.min]"
            value=""
            color="#333333"
            v-model="userInput.userCompany"
            class="input-group--focused"
          />

          <v-text-field
            name="input-10-2"
            label="Email or Username"
            value=""
            :rules="[rules.required, rules.min]"
            color="#333333"
            v-model="userInput.userEmail"
            class="input-group--focused"
          />

          <v-text-field
            name="input-10-2"
            color="#333333"
            label="Phone"
            :rules="[rules.required, rules.min]"
            value=""
            v-show="!isLogin"
            v-model="userInput.userPhone"
            class="input-group--focused"
          />

          <v-text-field
            :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show3 ? 'text' : 'password'"
            name="input-10-2"
            :label="this.$t('password')"
            :hint="this.$t('hintPasswordField')"
            value=""
            v-show="isLogin"
            color="#333333"
            v-model="userInput.userPassword"
            class="input-group--focused"
            @click:append="show3 = !show3"
            @keyup.enter="callback"
          />
          <!-- <v-checkbox
            :v-checkbox="isSupplier"
            v-show="isLogin"
            v-model="isSupplier"
            color="green"
            class="mb-5"
            hide-details
            light
            :label="'I am a Supplier.'"
          /> -->
          <!-- <v-switch
            style="margin-bottom: 7px; margin-top: 0"
            v-model="isTest"
            hide-details
            label="Live/Test"
          ></v-switch> -->
          <v-checkbox
            :v-checkbox="checkbox"
            v-show="!isLogin"
            color="green"
            class="mb-5"
            light
            :label="'Agree to the all policy of Data V Tech'"
          />
        </div>
        <div
          v-if="isError && !isLogin"
          class="d-flex justify-start"
          style="width: 100%; color: red"
        >
          <h5>*Please fill in all information</h5>
        </div>
        <g-recaptcha
          data-sitekey="6LcEqtQlAAAAAGHpVRo-Yb6sVo0o_3fa9jT2qhoP"
          class="input-group--focused width-100 d-flex justify-center"
          style="width: 100%"
          :data-validate="validate"
          :data-callback="callback"
        >
          <v-btn
            elevation="2"
            min-width="150px"
            :loading="loader"
            :disabled="loading"
            @click="loader = 'loading'"
            class="mb-3 width-100"
            >{{ isLogin ? $t("logIn") : $t("register") }}</v-btn
          >
        </g-recaptcha>
        <!-- <a class="switchTabMsg" @click="isLogin = !isLogin">{{
          isLogin ? $t("switchTabRegister") : $t("switchTabLogin")
        }}</a> -->
      </v-card>
    </div>
    <div class="d-flex align-center justify-center direction-column">
      <h4 class="d-flex" style="color: white">
        &copy; 2023 Developed by Data V Tech Solutions Company Limited
      </h4>
      <a class="d-flex" href="https://www.datavtech.com">www.datavtech.com</a>
    </div>
  </v-card>
</template>

<script>
import gRecaptcha from "@finpo/vue2-recaptcha-invisible";
import DecodeJWT from "../DecodeJWT";
import { callApiRegister, callApiLogin } from "../../../store/Method/index";

export default {
  components: {
    gRecaptcha,
  },
  mounted() {
    let token = localStorage.getItem("MTNToken");
    if (token != "" && token) {
      var info = DecodeJWT(token);
      if (info.exp > new Date().getTime() / 1000) {
        this.$router.push("/home");
      }
    }
    this.isSupplier = localStorage.getItem("isSupplierRole") == "true";
    // this.isTest =
    //   localStorage.getItem("Test") && localStorage.getItem("Test") == "true";
    this.isTest = false;
    localStorage.setItem("userinfo", "");
    localStorage.setItem("cust_num", "");
    localStorage.setItem("cust_uid", "");
  },
  data() {
    return {
      userInput: {
        userName: "",
        userPhone: "",
        userEmail: "",
        userCompany: "",
        userPassword: "",
      },
      isTest: false,
      isSupplier: false,
      isLogin: true,
      show3: false,
      checkbox: false,
      loading: false,
      loader: null,
      isError: false,
    };
  },
  watch: {
    isTest() {
      localStorage.setItem("Test", false);
      this.isTest = false;
    },
  },
  computed: {
    rules() {
      return {
        required: (value) => !!value || this.$t("required"),
        min: (v) => v.length >= 0 || this.$t("hintPasswordField"),
        emailMatch: () => this.$t("emailMatch"),
        passwordMatch: (v) =>
          this.userPassword === v || this.$t("passwordMatch"),
      };
    },
  },
  methods: {
    async processRegister() {
      await callApiRegister(this.userInput).then((res) => {
        if (res && res.message == "error") {
          alert("res" + res.error);
        } else {
          if (res != "") {
            alert(
              "Thank you for submitting your registration on our Customer Service Portal. We are pleased to inform you that your registration ticket Number: " +
                res +
                " has been successfully received and is now being processed. Once your registration has been approved, you will receive an email confirmation containing your login details. Thank you for your patience."
            );
            this.loader = null;
            this.isLogin = true;
            this.userInput = {
              userName: "",
              userCompany: "",
              userPhone: "",
              userEmail: "",
              userPassword: "",
            };
          }
        }
      });
    },
    async logIn() {
      // if (this.userInput.userEmail.includes("@")) state.vendor_num = 999;
      await callApiLogin(
        this.userInput.userEmail,
        this.userInput.userPassword
      ).then(async (res) => {
        if (res && res.message == "error") {
          alert("Login Fail!");
          this.loader = null;
        } else {
          if (!(res != "")) {
            alert("Login Fail!");
            this.loader = null;
          } else {
            localStorage.setItem("MTNToken", res);
            var info = DecodeJWT(res);
            if (info._user.status == 1 || info._user.status == 0) {
              await this.$store.dispatch("User/updateInfo", {
                user: info._user,
              });
              await this.$store.dispatch("User/DSPGetCompanyInfo");
              await this.$store.dispatch("User/getInfo");
            }
            (await info._user.status) == 1
              ? this.$router.push("/home")
              : info._user.status == 0
              ? !this.userInput.userEmail.includes("@")
                ? this.$router.push("/home")
                : this.$router.push("/change-password")
              : alert("Incorrect email or password!");
          }
        }
      });
    },
    validate() {
      return true;
    },
    async callback(token) {
      this.loader = "loading";
      if (token) {
        if (this.isLogin) {
          await this.logIn();
        } else {
          if (
            this.userInput.userName != "" &&
            this.userInput.userCompany != "" &&
            this.userInput.userPhone != "" &&
            this.userInput.userEmail != ""
          ) {
            await this.processRegister();
            this.isError = false;
          } else {
            this.isError = true;
          }
        }
      } else {
        alert("Please check you are not robot!!!");
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../assets/css/standard.scss";

.width-100 {
  width: 100%;
}

.button-submit {
  width: 200%;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  background-color: transparent;
  color: #7cc242;
  border-radius: 2em;
  border-width: 2px;
  background: 0 0;
  font-weight: bold;
  margin-top: 10px;
}

.direction-column {
  margin-top: 40px;
  flex-direction: column;
}

.bg {
  flex-direction: column;
  background-color: #006780;
  background-size: cover;
}

.hidden {
  display: none !important;
}

a:hover {
  font-weight: bolder;
}

.switchTabMsg {
  text-align: center;
  width: fit-content;
  color: black !important;
  text-decoration: underline;
}

.passwordRules {
  margin-top: -25px;
  margin-left: 15px;
  font-size: small;
  opacity: 0.6;
}
</style>
